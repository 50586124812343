import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('https://app2.oscar-black.com/api/me')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    modifyUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('https://app2.oscar-black.com/api/me', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    newPassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://app2.oscar-black.com/api/me/updatepassword', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
