<template>
  <b-form>
    <b-row>
      <!-- old password -->
      <b-col md="6">
        <b-form-group
          label="Votre ancien mot de passe"
          label-for="account-old-password"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-old-password"
              v-model="passwordValueOld"
              name="old-password"
              :type="passwordFieldTypeOld"
              placeholder="Ancien mot de passe"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconOld"
                class="cursor-pointer"
                @click="togglePasswordOld"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--/ old password -->
    </b-row>
    <b-row>
      <!-- new password -->
      <b-col md="6">
        <b-form-group
          label-for="account-new-password"
          label="Nouveau mot de passe"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-new-password"
              v-model="newPasswordValue"
              :type="passwordFieldTypeNew"
              name="new-password"
              placeholder="Nouveau mot de passe"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconNew"
                class="cursor-pointer"
                @click="togglePasswordNew"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--/ new password -->

      <!-- retype password -->
      <b-col md="6">
        <b-form-group
          label-for="account-retype-new-password"
          label="Confirmez le nouveau mot de passe de l'utilisateur"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="account-retype-new-password"
              v-model="RetypePassword"
              :type="passwordFieldTypeRetype"
              name="retype-password"
              placeholder="Nouveau mot de passe"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconRetype"
                class="cursor-pointer"
                @click="togglePasswordRetype"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--/ retype password -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-1 mr-1"
          @click="newpwd()"
        >
          Enregistrer
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-form>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    newpwd() {
      if (this.newPasswordValue === this.RetypePassword) {
        if (this.newPasswordValue !== '') {
          store.dispatch('app-user/newPassword', {
            id: this.id,
            new: this.newPasswordValue,
            old: this.passwordValueOld,
          })
            .then(() => {
              this.newPasswordValue = ''
              this.RetypePassword = ''
              this.passwordValueOld = ''
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Mot de passe à jour',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Le mot de passe a bien été mis à jour !',
                },
              })
            })
            .catch(() => {
            })
        }
      }
    },
  },
}
</script>

<style>

</style>
